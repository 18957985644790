import React from 'react';
import 'twin.macro';
import Moment from 'react-moment';
import NavigationLink from '@leuven2030/framework/Navigation/NavigationLink';
import ImageBackground from '@leuven2030/framework/Image/ImageBackground';
import truncate from 'lodash/truncate';

const CalendarItem = (props) => {
  return (
    <NavigationLink prismicMeta={props}>
      <div tw="flex py-2 pl-8" key={`event-${props._meta.uid}`}>
        <div tw="w-1/12 pt-4 cursor-pointer ">
          {/*  */}
          <ImageBackground
            tw="rounded w-20 h-20 rounded-full bg-black relative opacity-95 mt-6 sm:mt-0 -ml-6 sm:ml-0"
            src={props.cover.url}
            imgixParams={{
              w: 100,
              h: 100,
              fit: 'crop'
            }}
          >
            <div
              tw="bg-primary-400 w-14 h-14 text-white rounded-full flex flex-col items-center justify-center whitespace-nowrap rounded-full bg-primary-400 font-medium absolute left-3 -top-8 sm:-left-10 sm:top-0"
              className="font-sohne"
            >
              <Moment
                tw="text-white tracking-wide uppercase text-base mt-1 leading-4"
                format="DD"
              >
                {props.event_date_start}
              </Moment>
              <Moment tw="capitalize text-sm text-white leading-4" format="MMM">
                {props.event_date_start}
              </Moment>
            </div>
          </ImageBackground>
        </div>
        <div tw="w-11/12 ml-14 pt-4 border-t border-primary-400 border-opacity-20">
          {/* {node.cover && <img src={node.cover.url} width={100} />} */}
          <div
            tw="text-lg font-medium hover:text-primary-400 cursor-pointer"
            className="font-sohne"
          >
            {props.title}
          </div>
          <div tw="text-base">
            {truncate(props.description, { length: 180 })}
          </div>
        </div>
      </div>
    </NavigationLink>
  );
};

export default CalendarItem;

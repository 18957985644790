import React from 'react';
import PrismicText from '@leuven2030/framework/Prismic/PrismicText';
import Image from '@leuven2030/framework/Image/Image';
import { StyleContent } from '@leuven2030/food/styles/general';
import 'twin.macro';
import NavigationLink from '@leuven2030/framework/Navigation/NavigationLink';
const SectionIntro = ({
  text,
  image: { url },
  button_label,
  button_link,
  ...rest
}) => {
  const navigationProps = {
    prismicMeta: button_link,
    url: button_link.url
  };
  return (
    <div tw="bg-gray-100 py-12" {...rest}>
      <div css={StyleContent} tw="grid lg:grid-cols-2 gap-6">
        <div tw="space-y-3">
          <PrismicText text={text} tw="prose text-sm" />
          <NavigationLink tw="text-primary-500" {...navigationProps}>
            {button_label}
          </NavigationLink>
        </div>
        <div>
          <NavigationLink {...navigationProps}>
            <Image
              src={url}
              imgixParams={{
                w: 800,
                h: 400,
                fit: null
              }}
              //css={{ width: 500 }}
              imageHeight={360}
              tw="shadow-lg overflow-hidden rounded-md"
            />
          </NavigationLink>
        </div>
      </div>
    </div>
  );
};

export default SectionIntro;

import Page, { PageContext } from '@leuven2030/framework/Page/Page';
import 'twin.macro';
import React, { useContext } from 'react';
import CalendarItem from '@leuven2030/food/Calendar/CalendarItem';

const CalendarList = () => {
  const {
    prismic: {
      events: { edges }
    }
  } = useContext(PageContext);
  return (
    <div>
      {edges.map((e, index) => {
        const { node } = e;
        return <CalendarItem {...node} key={index} />;
      })}
    </div>
  );
};

export default CalendarList;

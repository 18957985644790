import tw from 'twin.macro';
import { css } from '@emotion/react';

export const StyleContent = tw`px-3 sm:px-14 max-w-screen-lg mx-auto w-full`;

export const StyleParallaxCover = css`
  img {
    object-fit: cover;
  }
`;
